var donateWidgetForm = document.querySelectorAll(".donation-widget-form");

if (donateWidgetForm != 'undefined' && donateWidgetForm != null) {
  donateWidgetForm.forEach(widgetFunction);

  function widgetFunction(donateWidgetForm) {
    let radioInputs = donateWidgetForm.querySelectorAll(".amount-radio");
    let amountInput = donateWidgetForm.querySelector(".donate-amount");

    // update amount field by radio inputs
    for (var i = 0; i < radioInputs.length; i++) {
      amountUpdate(radioInputs[i], amountInput);
    }
    // if amount input is used, uncheck radio buttons
    amountInput.oninput = function() {
      for (var i = 0; i < radioInputs.length; i++) {
        radioInputs[i].checked = false;
      }
    }

    // redirect to the right URL with amount parameter only
    donateWidgetForm.addEventListener("submit", function(e){
      e.preventDefault();

      let urlParams = "?amount=" + amountInput.value;
      window.location.href = donateWidgetForm.action + urlParams;
    });
  }
}

function amountUpdate(radioField, amountField) {
  radioField.onchange = function() {
    amountField.value = radioField.value.replace(".00", "");
  }
}